<template>
  <div class="content">
    <div class="contact-wrapper mb-4">
      <custom-dialog :isDialogOpen="isDialogOpen" @close="closeDialog" @save="saveOrUpdateTempData"/>
      <div class="contact-content">
        <!-- <SaveSuccessMessage v-if="isShowSuccessMessage" /> -->
        <ListedCarMessage />
        <b-card class="px-5 pb-4 rounded-2 mb-5 pt-0">
          <div class="review-content">
            <div class="w-100 center" :class="!isMobile ? 'mt-4' : checkReadonly ? '' : 'mt-3'">
              <h1
                class="font-weight-bold fs-20 content-title"
                style="margin-bottom: 0.5rem"
              >
                SALE INFORMATION
              </h1>
              <SectionDivider dividerText="PRICING & FEES"></SectionDivider>
              <b-row :class="!isMobile ? 'mt-30' : ''">
                <b-col cols="12" md="6" :class="!isShowPackageList ? 'mb-3' : ''">
                  <float-label label="Reserve price" :class="`${(checkReadonly) ? 'float-label' : ''}`">
                    <b-input
                      v-model.trim="info.reservePrice"
                      ref="reservePriceInput"
                      placeholder="Start typing"
                      autocomplete="off"
                      required
                      type="number"
                      pattern="\d*"
                      @keypress="isNumber"
                      :class="[
                        {
                          error: $v.info.reservePrice.$error,
                        },
                        'shadow-none',
                      ]"
                      :disabled="checkReadonly"
                      @keyup="removeFirstZero"
                      @blur="setNextInputNumber"
                      @input="handleFeeCalculation"
                    />
                    <img
                      src="@/assets/images/icons/ic_help_circle.svg"
                      style="width: 20px; height: 20px; z-index: 1"
                      class="help-icon"
                      v-on:mouseenter="hoverTooltip"
                      v-on:mouseleave="blurTooltip"
                      v-on:click="handleMobileTap"
                    />
                    <p
                      v-if="
                        $v.info.reservePrice.$dirty &&
                        !$v.info.reservePrice.required
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter your reserve price</i>
                    </p>
                    <p
                      v-else-if="
                        $v.info.reservePrice.$dirty &&
                        !$v.info.reservePrice.numeric
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter only numerics!</i>
                    </p>
                    <p
                      v-else-if="
                        $v.info.reservePrice.$dirty &&
                        !$v.info.reservePrice.minLengthValid
                      "
                      class="msg-error mb-0"
                    >
                      <i>Reserve price must have at least 3 digits.</i>
                    </p>
                    <p
                      v-else-if="
                        $v.info.reservePrice.$dirty &&
                        !$v.info.reservePrice.maxLength
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter 1 to 20 number!</i>
                    </p>
                    <div 
                      class="reserve-price-banner mb-3 mt-2"
                      v-show="showReservePriceBanner && !$v.info.reservePrice.$error"
                      v-if="successFee.active && !isShowPackageList"
                    >
                      <div class="banner-text mb-1">
                        <custom-icon
                          name="info_black"
                          width="100%"
                          class="banner-icon"
                        />
                        <p class="mb-0">Estimated success fee: ${{estimatedSuccessFee}}</p>
                      </div>
                      <a
                        :href="successFee.link"
                        target="_blank"
                        class="banner-link"
                        >NO DEAL, NO FEE - LEARN MORE</a
                      >
                    </div>
                    <div ref="reservePriceTooltip" class="reserve-price-tooltip">
                      <p class="mb-0 font-weight-thin">{{floatLabelTooltip.content}}</p>
                    </div>
                  </float-label>
                </b-col>
              </b-row>
              <div v-if="isShowPackageList" class="package-container">
                <div class="reserve-price-note font-weight-regular">
                  <p class="mb-0 fs-16">Maximise your reach and attract more buyers with our listing packages :</p>
                  <u>
                    <a
                      :href="successFee.link"
                      target="_blank"
                      >NO DEAL, NO FEE - LEARN MORE</a
                    >
                  </u>
                </div>
                <div class="package-main d-flex justify-content-between">
                  <template v-for="(data, index) in packages">
                    <div :key="data.id" class="package-list cursor-pointer" @click="handlePackageItems(data.name, index)">
                      <div
                        class="package-items cursor-pointer" 
                        :class="{ 
                          'mt-4': isMobile && data.name === 'Standard',
                          'mt-3': isMobile,
                          'custom-border': data.isExpanded,
                          'custom-border-error-bottom': $v.info.productTier.$error,
                          'custom-border-error-bottom-activated': $v.info.productTier.$error && data.isExpanded,
                          'custom-selected-border-bottom': info.productTier === data.name,
                          'custom-selected-border-bottom-activated': info.productTier === data.name && data.isExpanded,
                        }"
                        :style="{ backgroundColor: data.name === 'Standard' ? '#F8F8F8' : '' }"
                      >
                        <div v-if="data.name === 'Standard'" class="most-popular-badge fs-14 font-weight-regular"><p class="mb-0">MOST POPULAR</p></div>
                        <div
                          class="d-flex justify-content-between align-items-center mx-4 mt-3"
                        >
                          <b-form-radio
                            v-model="info.productTier" 
                            :value="data.name"
                            :name="data.id"
                            class="custom-radio mb-0 fs-16"
                            @click.stop
                            :disabled="!isPackageEnabled || checkReadonly"
                          >
                            {{ data.name }}
                          </b-form-radio>
                          <span @click.stop="active(index)" :id="`package-${index}`" class="cursor-pointer">
                            <img
                              :aria-controls="data.id"
                              :aria-expanded="data.isExpanded"
                              :src="require('@/assets/images/icons/ic_package_arrow_down.svg')"
                              :style="data.isExpanded ? 'transform: rotate(180deg)' : ''"
                            />
                          </span>
                        </div>
                        <div class="package-fee d-flex justify-content-between mx-4 fs-14">
                          <p>SUCCESS FEE:</p>
                          <p>{{ isPackageEnabled ? `$${estimatedSuccessFee + data.price}` : '-' }}</p>
                        </div>
                      </div>
                      <b-collapse :id="data.id" :key="'package-' + index" v-model="data.isExpanded">
                        <div 
                          class="package-feature fs-14" 
                          :class="{
                            'custom-selected-border-top': info.productTier === data.name,
                            'custom-selected-border-top-activated': info.productTier === data.name && data.isExpanded,
                            'custom-border-error-top': $v.info.productTier.$error,
                            'custom-border-error-top-activated': $v.info.productTier.$error && data.isExpanded,
                          }"
                          :style="{ backgroundColor: data.name === 'Standard' ? '#F8F8F8' : '' }"
                        >
                          <p class="package-feature-title mb-2 ml-3">{{ data.featureTitle }}</p>
                          <ul class="package-feature-list ml-3 pl-0 font-weight-regular">
                            <li v-for="(feature, index) in data.featureList" class="package-feature-items list-unstyled d-flex align-items-center mb-2" :key="index">
                              <img :src="require('@/assets/images/icons/ic_feature_ticked.svg')" alt="ic_feature_ticked">
                              <p class="mb-0 ml-2">{{ feature }}</p>
                            </li>
                          </ul>
                        </div>
                      </b-collapse>
                    </div>
                  </template>
                </div>
                <div v-if="isShowPackageList" class="listing-tiers-disclaimer">
                  <p v-for="(line, index) in listingTiersDisclaimers" :key="index" class="listing-tiers-disclaimer-content">
                    {{ line }}
                  </p>
                </div>
                <p v-if="!isMobile && $v.info.productTier.$error" class="package-err-text fs-16 font-weight-regular mb-0 mt-2">Please select a listing package</p>
                <u class="package-compare d-block fs-16 text-center mt-2">
                  <a href="https://www.autoflip.com.au/listing-packages" target="_blank">Compare all packages</a>
                </u>
              </div>
              <SectionDivider dividerText="SALE DETAILS"></SectionDivider>
              <b-row :class="!isMobile ? 'mt-30' : ''">
                <b-col cols="12"  md="6">
                  <google-place-autocomplete
                    label="Car pick-up location"
                    v-model="authenticateData.customerPostalCode"
                    :error="$v.authenticateData.customerPostalCode.$error"
                    :helperText="postalCodeHelperText"
                    name="location"
                    :placeholder="isNZ ? 'Start typing your full address' : 'Start typing your postcode'"
                    :selectDisabled="checkReadonly"
                    class="pickup-location"
                    :isNZ="isNZ"
                    @input="handleLocationInput"
                    :isMobile="isMobile"
                  />
                </b-col>
                <b-col cols="12"  md="6">
                  <custom-select
                    label="When are you looking to sell?"
                    v-model="info.sell"
                    :options="sells"
                    :error="$v.info.sell.$error"
                    helperText="Please select one option"
                    @blur="setNextInputNumber"
                    :selectDisabled="checkReadonly"
                    :isLeftAlign=true
                  />
                </b-col>
                <b-col cols="12" md="6" v-if="selling">
                  <custom-select
                    label="When is your car available?"
                    v-model="info.pickUp"
                    :options="pickUps"
                    :error="$v.info.pickUp.$error"
                    helperText="Please select pick-up time"
                    @blur="setNextInputNumber"
                    :selectDisabled="checkReadonly"
                  />
                </b-col>
                <b-col cols="12" md="6" v-if="sellingLater && !isNZ">
                  <custom-select
                    label="When will you be ready to sell later?"
                    v-model="info.pickUpLater"
                    :options="pickUpsLater"
                    :error="$v.info.pickUpLater.$error"
                    helperText="Please select pick-up time"
                    @blur="setNextInputNumber"
                    :selectDisabled="checkReadonly"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <custom-select
                    v-if="!isNZ"
                    label="Is your car advertised elsewhere?"
                    v-model="info.advertised"
                    :options="advertisements"
                    :error="$v.info.advertised.$error"
                    helperText="Please select one option"
                    @blur="setNextInputNumber"
                    :selectDisabled="checkReadonly"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <custom-multi-select
                    v-if="advertising && !isNZ" 
                    label="What platform is it currently listed on?"
                    v-model="info.platform"
                    :options="platforms"
                    placeholder="Select platform(s)"
                    @blur="setNextInputNumber"
                    :selectDisabled="checkReadonly"
                    :optional="true"
                  />
                </b-col>
              </b-row>
              <SectionDivider dividerText="PERSONAL INFO"></SectionDivider>
              <b-row>
                <b-col cols="12"  md="6" class="mb-3">
                  <float-label label="Name">
                    <b-input
                      v-model.trim="authenticateData.customerName"
                      ref="name"
                      placeholder="Enter your full name"
                      autocomplete="off"
                      required
                      :class="[
                        {
                          error: $v.authenticateData.customerName.$error,
                        },
                        'shadow-none',
                      ]"
                      @keyup.enter="setNextInput('email')"
                    />
                    <p
                      v-if="
                        $v.authenticateData.customerName.$dirty &&
                        !$v.authenticateData.customerName.required
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter your name</i>
                    </p>
                    <p
                      v-else-if="
                        $v.authenticateData.customerName.$dirty &&
                        !$v.authenticateData.customerName.valid
                      "
                      class="msg-error mb-0"
                    >
                      <i
                        >Please enter only alphabet characters, numerics and
                        space!</i
                      >
                    </p>
                    <p
                      v-else-if="
                        $v.authenticateData.customerName.$dirty &&
                        !$v.authenticateData.customerName.maxLength
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter 1 to 110 characters!</i>
                    </p>
                    <p
                      v-else-if="$v.authenticateData.customerName.$dirty &&  !$v.authenticateData.customerName.validFullName"
                      class="msg-error mb-0"
                    >
                      <i>Please enter your full name.</i>
                    </p>
                    <p v-else class="msg-error"></p>
                  </float-label>
                </b-col>
                <b-col cols="12"  md="6" class="mb-3">
                  <float-label label="Email">
                    <b-input
                      v-model.trim="authenticateData.email"
                      ref="email"
                      :placeholder="emailGhostText ? emailGhostText : 'Enter your email'"
                      autocomplete="off"
                      required
                      :class="[
                        {
                          error: $v.authenticateData.email.$error || !isCorrectEmailDomain,
                        },
                        'shadow-none',
                      ]"
                      @keyup.enter="setNextInput('mobile')"
                    />
                    <p
                      v-if="
                        $v.authenticateData.email.$dirty &&
                        !$v.authenticateData.email.required
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter your email</i>
                    </p>
                    <p
                      v-else-if="
                        $v.authenticateData.email.$dirty &&
                        !$v.authenticateData.email.email
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter a valid email address!</i>
                    </p>
                    <p
                      v-else-if="
                        $v.authenticateData.email.$dirty &&
                        !$v.authenticateData.email.maxLength
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter 1 to 256 characters!</i>
                    </p>
                    <p
                      v-else-if="!isCorrectEmailDomain"
                      class="msg-error mb-0"
                    >
                      <i>Invalid email address entered!</i>
                    </p>
                  </float-label>
                </b-col>
                <b-col cols="12"  md="6" class="mb-0">
                  <float-label label="Mobile">
                    <b-input
                      v-model.trim="authenticateData.customerMobile"
                      ref="mobile"
                      placeholder="Enter your phone"
                      autocomplete="off"
                      required
                      :type="isNZ ? 'tel' : 'number'"
                      :class="[
                        {
                          error: $v.authenticateData.customerMobile.$error,
                        },
                        'shadow-none',
                      ]"
                      @keydown.space.prevent
                      @focusout="handleFocusOut"
                    />
                    <p
                      v-if="
                        $v.authenticateData.customerMobile.$dirty &&
                        !$v.authenticateData.customerMobile.required
                      "
                      class="msg-error mb-0"
                    >
                      <i>Please enter your phone</i>
                    </p>
                    <p
                      v-else-if="isPhoneNumberValid || !isNotShowErrMsg"
                      class="msg-error mb-0"
                    >
                      <i>Please enter a valid mobile number starting with '{{ isNZ ? "+642' or '02" : "04"}}'</i>
                    </p>
                    <p
                      v-else-if="isInPhoneNumberRule || phoneNumberRuleCheck"
                      class="msg-error mb-0"
                    >
                      <i>Invalid mobile number</i>
                    </p>
                  </float-label>
                </b-col>
              </b-row>
            </div>
            <QuestionAndAnswer :questions="questions" />
            <div v-if="isMobile" style="height: 30px;"></div>
            <btn-group
              @onSubmit="submitForm"
              @onCancel="goBack"
              @onSave="openDialog"
              :disableSubmit="!activeBtnAction"
              :query=this.$route.query
              :isMobile="isMobile"
              submitText="Confirm"
            />
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  required,
  maxLength,
  requiredIf,
  numeric,
  helpers,
  email,
} from "vuelidate/lib/validators";
import mixin from "@/mixins/common";
import {
  SET_SALE_INFORMATION,
  CLEAR_SALE_INFO_OPTION,
  UPDATE_DATA,
  SET_PRODUCT_TIER,
} from "@/store/authenticate-seller/actions";
import { locationValMap, logError, LeadSourceStringMap } from '../../helper/utils';
import customerDetailService from "@/services/customer-detail";
import debounce from "lodash/debounce";

const regexName = helpers.regex("name", /^[a-zA-Z0-9 ]+$/);
const regexPhoneRule = helpers.regex(
  "number",
  /^04\d{8,}$/
);
const regexPhoneRuleNZ = helpers.regex(
  "number",
  /^(02|\+642)/
);

export default {
  name: "Contact",
  mixins: [mixin],
  components: {
    FloatLabel: () => import("../../components/common/FloatLabel.vue"),
		BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    CustomSelect: () => import("../../components/common/CustomSelect"),
    CustomDialog: () => import("../../components/common/CustomDialog"),
    ListedCarMessage: () => import("../../components/common/ListedCarMessage.vue"),
    QuestionAndAnswer: () => import("../../components/common/QuestionAndAnswer.vue"),
    SectionDivider: () => import("../../components/common/SectionDivider.vue"),
    GooglePlaceAutocomplete: () => import("../../components/common/GooglePlaceAutocomplete.vue"),
    CustomIcon: () => import("../../components/common/CustomIcon"),
    CustomMultiSelect: () => import("../../components/common/CustomMultiSelect"),
  },
  data() {
    return {
      selling: false,
      sellingLater: false,
      // showExplanation: true,
      curStepInput: 1,
      isMobile: true,
      isDialogOpen: false,
      inputStep: [
        {
          step: 1,
          key: "customerName",
        },
        {
          step: 2,
          key: "customerEmail",
        },
        {
          step: 3,
          key: "customerMobile",
        },
        {
          step: 4,
          key: "customerPostalCode",
        },
        {
          step: 5,
          key: "",
        },
      ],
      isActiveReportIssue: true,
      questions: [],
      suburbAndPostcodes: [],
      isPhoneNumberValid: false,
      isInPhoneNumberRule: false,
      isNotShowErrMsg: false,
      isValidLocation: false,
      estimatedSuccessFee: 0,
      handleFeeCalculation: null,
      advertising: false,
      isCorrectEmailDomain: true,
      isNotShowFullNameErr: true,
    };
  },
  computed: {
    ...mapState({
      steps: (state) => state.common.steps,
      info: (state) => state.authenticateSeller.saleInfo,
      authenticateData: (state) => state.authenticateSeller,
      uploadImage: (state) => state.photo.uploadImage,
      conditionInfo: (state) => state.authenticateSeller.conditionInfo,
      isIdle: (state) => state.common.isIdle,
      onEdit: (state) => state.common.onEdit,
      showReservePriceBanner: (state) => state.common.showReservePriceBanner,
      policyDocumentsLinks: (state) => state.commonVariables.policyDocumentsLinks,
      successFee: (state) => state.commonVariables.successFee,
      emailGhostText: (state) => state.commonVariables.copyBlocks.emailGhostText,
      packages: (state) => state.commonVariables.packages,
      listingTiers: (state) => state.commonVariables.listingTiers,
      listingTiersExcludedLeadSourceList: (state) => state.commonVariables.listingTiersExcludedLeadSourceList,
      listingTiersDisclaimers: (state) => state.commonVariables.listingTiersDisclaimers,
    }),
    activeBtnAction: function () {
      return (
        !this.$v.info.$invalid &&
        !this.$v.authenticateData.$invalid &&
        this.isNotShowErrMsg &&
        this.phoneNumberCheckCondition &&
        this.isValidLocation &&
        this.isCorrectEmailDomain &&
        this.isNotShowFullNameErr
      );
    },
    reservePrice() {
      return (
        "$" + new Intl.NumberFormat().format(this.info.reservePrice)
      );
    },
    mobileMediaQuery: function() { 
      return window.matchMedia('(min-width: 768px)');
    },
    sells: function() {
      return ["Now", "Later",  "I'm not selling"];
    },
    pickUps: function() {
      return ["Within a week after sale", "More than a week after sale"];
    },
    advertisements: function() {
      return ["Yes", "No"];
    },
    platforms: function() {
      return ["Carsales", "Facebook", "Gumtree", "Others"];
    },
    pickUpsLater: function() {
      return ["Within a month", "Within 1-3 months", "Within 3-6 months", "Later than 6 months", "I'm not sure"];
    },
    checkReadonly: function() {
      return this.authenticateData.listed || this.authenticateData.readonlyMode;
    },
    phoneNumberRuleCheck() {
      return (
        this.$v.authenticateData.customerMobile.$dirty &&
        !this.$v.authenticateData.customerMobile.rule
      );
    },
    postalCodeHelperText() {
      return this.authenticateData.customerPostalCode !== ""
        ? "Please select a valid location from the dropdown in the format </br> 'Suburb State Postcode', e.g. 'Bondi Beach NSW 2026'"
        : "Please enter a suburb/postcode";
    },
    isNZ: function() {
      return parseInt(this.authenticateData.locid) === 2;
    },
    phoneNumberStartsCondition: function() {
      return this.isNZ
        ? this.authenticateData.customerMobile === "0" ||
            this.authenticateData.customerMobile === "02" ||
            this.authenticateData.customerMobile === "+" ||
            this.authenticateData.customerMobile === "+6" ||
            this.authenticateData.customerMobile === "+64" ||
            this.authenticateData.customerMobile === "+642"
        : this.authenticateData.customerMobile === "0" ||
            this.authenticateData.customerMobile === "04"
    },
    regexPhoneRuleCondition: function () {
      return this.isNZ
        ? (this.authenticateData.customerMobile.startsWith("02") &&
            this.authenticateData.customerMobile.length >= 9 &&
            this.authenticateData.customerMobile.length <= 11) ||
            (this.authenticateData.customerMobile.startsWith("+642") &&
              this.authenticateData.customerMobile.length >= 10 &&
              this.authenticateData.customerMobile.length <= 12)
        : this.authenticateData.customerMobile.length === 10;
    },
    phoneNumberValidCheckCondition: function () {
      return this.isNZ
        ? this.phoneNumberStartsCondition ||
            (this.authenticateData.customerMobile.startsWith("02") &&
              this.authenticateData.customerMobile.length >= 9 &&
              this.authenticateData.customerMobile.length <= 11) ||
            (this.authenticateData.customerMobile.startsWith("+642") &&
              this.authenticateData.customerMobile.length >= 10 &&
              this.authenticateData.customerMobile.length <= 12)
        : this.authenticateData.customerMobile === "0" ||
            this.authenticateData.customerMobile === "04" ||
            (this.authenticateData.customerMobile.startsWith("04") &&
              this.authenticateData.customerMobile.length === 10);
    },
    phoneNumberCheckCondition: function () {
      return this.isNZ
        ? this.regexPhoneRuleCondition
        : this.authenticateData.customerMobile.startsWith("04") &&
            this.authenticateData.customerMobile.length === 10;
    },
    floatLabelTooltip() {
      return {
        content:
          this.checkReadonly
            ? "This is the lowest price you'll sell for. Don't worry, our large dealer network will competitively bid, ensuring you get the best price. If you'd like to change your price, please contact us below for support."
            : "This is the lowest price you'll sell for. Don't worry, our large dealer network will competitively bid, ensuring you get the best price.",
      };
    },
    isPackageEnabled() {
      return this.info.reservePrice.toString().length >= 3;
    },
    locationDetails() {
      return locationValMap(this.authenticateData.customerPostalCode, this.isNZ);
    },
    isShowPackageList() {
      return (
        !this.isNZ &&
        this.packages.every((pkg) =>
          pkg.state.includes(this.locationDetails.state)
        ) &&
        this.listingTiers &&
        !this.listingTiersExcludedLeadSourceList?.includes(LeadSourceStringMap[this.authenticateData.leadSource] || this.authenticateData.leadSource)
      );
    },
  },
  validations: {
    info: {
      reservePrice: {
        required,
        numeric,
        maxLength: maxLength(20),
        minLengthValid: function() {
          if (this.isNZ || !this.isShowPackageList) {
            return true;
          } else {
            return this.isPackageEnabled && this.isShowPackageList;
          }
        },
      },
      sell: {
        required,
      },
      pickUp: {
        required: requiredIf(function(){
          return this.info.pickUp === '' && this.info.sell === "Now"
        })
      },
      pickUpLater: {
        required: requiredIf(function(){
          return this.info.pickUpLater === '' && this.info.sell === "Later" && !this.isNZ
        })
      },
      advertised: {
        required: requiredIf(function(){
          return !this.isNZ
        })
      },
      productTier: {
        required: requiredIf(function(){
          return this.info.reservePrice.toString().length >= 3 && this.isShowPackageList
        })
      },
    },
    authenticateData: {
      customerName: {
        required,
        valid: regexName,
        maxLength: maxLength(110),
        validFullName: function() {
          return this.fullNameValidCheck(this.authenticateData.customerName)
        }
      },
      email: {
        required,
        email,
        maxLength: maxLength(256),
      },
      customerMobile: {
        required,
        valid: function() {
          return this.isNotShowErrMsg;
        },
        rule: function () {
          if (this.regexPhoneRuleCondition) {
            return this.isNZ
              ? regexPhoneRuleNZ(this.authenticateData.customerMobile)
              : regexPhoneRule(this.authenticateData.customerMobile);
          }
          return true;
        },
      },
      customerPostalCode: {
        required,
        valid: function() {
          const locationDetails = locationValMap(this.authenticateData.customerPostalCode, this.isNZ);
          return this.isNZ ? locationDetails.state : (locationDetails.postCode && locationDetails.state);
        }
      },
    },
  },
  methods: {
    async submitForm() {
      this.$v.info.$touch();
      this.$v.authenticateData.$touch();
      if (
        !this.phoneNumberValidCheck() ||
        (!this.$v.info.$invalid &&
          !this.$v.authenticateData.$invalid &&
          (this.phoneNumberStartsCondition))
      ) {
        this.isNotShowErrMsg = false;
      }
      if (!this.fullNameValidCheck(this.authenticateData.customerName)) this.isNotShowFullNameErr = false;
      if (
        this.activeBtnAction
      ) {
        this.$router.push({ name: "navigation", query: this.$route.query });
        if (!this.checkReadonly) {
          await this.$store.dispatch(SET_SALE_INFORMATION, {
            ...this.info,
            reservePrice: parseInt(this.info.reservePrice),
          });
        }
      }
    },
    async goBack() {
      const price = parseInt(this.info.reservePrice);
      if (!price) {
        this.$store.dispatch('setShowReservePriceBanner', false);
      }
      await this.$store.dispatch(SET_SALE_INFORMATION, {
        ...this.info,
        reservePrice: price,
      });
      this.$router.push({
        name: "navigation",
        query: this.$route.query,
      });
    },
    removeFirstZero() {
      if (/^0/.test(this.info.reservePrice)) {
        this.info.reservePrice =
          this.info.reservePrice.replace(/^0+/, "");
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode >= 48 &&
        charCode <= 57 &&
        !(charCode === 48 && this.info.kms === "")
      ) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
    setNextInputNumber() {
      for (const item of this.inputStep) {
        if (!this.info[item.key] || item.key === "") {
          this.curStepInput = item.step;
          break;
        }
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    openJiraIssue(e) {
      e.preventDefault();
      window.showCollectorDialog();
    },
    active(num) {
      if (!this.isMobile) {
        this.packages.forEach(pkg => pkg.isExpanded = !pkg.isExpanded);
      } else {
        this.packages[num].isExpanded = !this.packages[num].isExpanded;
      }
    },
    activeReportIssue() {
      this.isActiveReportIssue = !this.isActiveReportIssue;
    },
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    handleBeforeUnload(event) {
      if (!this.isIdle) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    async saveOrUpdateTempData() {
      this.$store.dispatch(UPDATE_DATA, this.authenticateData);
    },
    phoneNumberValidCheck() {
      if (this.phoneNumberValidCheckCondition) {
        this.isNotShowErrMsg = true;
        return true;
      } else {
        this.isNotShowErrMsg = false;
        return false;
      }
    },
    handleFocusOut() {
      if (this.authenticateData.customerMobile.length > 0) {
        this.phoneNumberValidCheck();
        this.$v.authenticateData.customerMobile.$touch();
      } 
    },
    hoverTooltip: function() {
      if (!this.isMobile) {
        this.$refs.reservePriceTooltip.style.visibility = "visible";
      }
    },
    blurTooltip: function() {
      this.$refs.reservePriceTooltip.style.visibility = "hidden";
    },
    handleMobileTap: function(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.isMobile) {
        this.$refs.reservePriceTooltip.style.visibility = "visible";
      }
    },
    handleLocationInput: function (val) {
      if (val !== '') {
        this.isValidLocation = true;
      } else {
        this.isValidLocation = false;
      }
    },
    getSuccessFee: function() {
      if (this.info.reservePrice) {
        customerDetailService
          .getSuccessFee({
            reservePrice: this.info.reservePrice,
            locid: parseInt(this.authenticateData.locid),
          })
          .then(({ data }) => {
            if (typeof data !== "number") {
              this.$store.dispatch('setShowReservePriceBanner', false);
            } else {
              this.estimatedSuccessFee = data;
            }
          })
          .catch((error) => {
            logError(`Get success fee error: ${error}`);
            this.$store.dispatch('setShowReservePriceBanner', false);
          });
      } else {
        this.estimatedSuccessFee = 0;
      }
    },
    toggleMobilePackage() {
      this.packages.forEach((pkg) => {
        if (pkg.name !== 'Standard') pkg.isExpanded = false;
      });
    },
    handlePackageItems(name, index) {
      if (!this.info.reservePrice || (!this.isPackageEnabled && this.isShowPackageList)) {
        this.$v.info.reservePrice.$touch();
        if (this.isMobile) {
          this.scrollToTop();
        }
      }
      if (this.isPackageEnabled && !this.checkReadonly) this.info.productTier = name;
      // Handle special case for mobile
      if (this.isMobile && !this.packages[index].isExpanded && this.info.productTier === name) {
        this.active(index);
      }
    },
    async checkEmailWithDns(email) {
      if (!email.includes("@") || this.$v.authenticateData.email.$error) return;
      const domain = email.split("@")[1];
      try {
        const response = await customerDetailService.resolveDomain(domain);
        this.isCorrectEmailDomain =
          response.status === 200 && response.data ? true : false;
      } catch (error) {
        console.error("Error resolving domain:", error);
        this.isCorrectEmailDomain = false;
      }
    },
    fullNameValidCheck(fullName) {
      const words = fullName.split(/\s+/);
      if (words.length < 2 || words.some(word => word.length < 2)) {
        this.isNotShowFullNameErr = false;
        return false;
      } else {
        this.isNotShowFullNameErr = true;
        return true;
      }
    }
  },
  watch: {
    "info.reservePrice": function(val) {
      if (val) {
        this.curStepInput = 1;
        this.$store.dispatch('setOnEdit', true);
        this.$store.dispatch('setOnEditPage', true);
        if (val.toString().length >= 3) {
          this.$store.dispatch('setShowReservePriceBanner', true);
        } else {
          this.info.productTier = '';
        }
      }
    },
    "info.sell": function(val) {
      if (val) {
        this.curStepInput = 2;
        this.$store.dispatch('setOnEdit', true);
        this.$store.dispatch('setOnEditPage', true);
      }
      if (val === "Now") {
        this.selling = true;
        this.sellingLater = false;
        this.$store.dispatch(CLEAR_SALE_INFO_OPTION, 'pickUpLater');
      } else if (val === "Later") {
        this.selling = false;
        this.sellingLater = true;
        this.$store.dispatch(CLEAR_SALE_INFO_OPTION, 'pickUp');
      } else {
        this.selling = false;
        this.sellingLater = false;
        this.$store.dispatch(CLEAR_SALE_INFO_OPTION, 'pickUp');
        this.$store.dispatch(CLEAR_SALE_INFO_OPTION, 'pickUpLater');
      }
    },
    "info.pickUp": function(val) {
      if (val) {
        this.curStepInput = 3;
        this.$store.dispatch('setOnEdit', true);
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    "info.advertised": function(val) {
      if (val) {
        this.curStepInput = 4;
        this.$store.dispatch('setOnEdit', true);
        this.$store.dispatch('setOnEditPage', true);
      }
      if (val === "Yes") {
        this.advertising = true;
      } else {
        this.advertising = false;
        this.$store.dispatch(CLEAR_SALE_INFO_OPTION, 'platform');
      }
    },
    "info.platform": function(val, prevVal) {
      if (val) {
        this.curStepInput = 5;
        if (JSON.stringify(prevVal) !== JSON.stringify(val)) {
          this.$store.dispatch('setOnEditPage', true);
        }
      }
    },
    "info.pickUpLater": function(val) {
      if (val) {
        this.curStepInput = 6;
        this.$store.dispatch('setOnEdit', true);
        this.$store.dispatch('setOnEditPage', true);
      }
    },
    curStepInput: function () {
      let progress = 0;
      for (const item of this.inputStep) {
        if (this.info[item.key] && item.key !== "") {
          progress = progress + (10 / (this.inputStep.length - 1));
        }
      }
      // this.$store.dispatch("setProgress", Math.floor(progress) * 10);
    },
    "authenticateData.customerMobile": function() {
      this.$v.authenticateData.customerMobile.$touch();
      if (
        this.authenticateData.customerMobile.startsWith("04") &&
        this.authenticateData.customerMobile.length < 10
      ) {
        this.isPhoneNumberValid = false;
        this.isInPhoneNumberRule = false;
      } else if (!this.phoneNumberValidCheck()) {
        this.isPhoneNumberValid = true;
      } else if (this.phoneNumberRuleCheck) {
        this.isPhoneNumberValid = false;
        this.isInPhoneNumberRule = true;
      } else {
        this.isPhoneNumberValid = false;
        this.isInPhoneNumberRule = false;
      }
      this.$store.dispatch('setOnEdit', true);
      this.$store.dispatch('setOnEditPage', true);
    },
    "authenticateData.customerName": function(val) {
      this.$store.dispatch('setOnEdit', true);
      this.$store.dispatch('setOnEditPage', true);
      if (!this.fullNameValidCheck(val)) {
        this.isNotShowFullNameErr = false;
      }
    },
    "authenticateData.email": function(val) {
      this.$store.dispatch('setOnEdit', true);
      this.$store.dispatch('setOnEditPage', true);
      this.handleResolveDomain(val);
    },
    "authenticateData.customerPostalCode": function() {
      this.$store.dispatch('setOnEdit', true);
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.productTier": function(val) {
      if (val) {
        this.$store.dispatch(SET_PRODUCT_TIER, val);
        this.$store.dispatch('setOnEditPage', true);
      }
    }
  },
  mounted() {
    if (this.info.reservePrice) {
      this.getSuccessFee();
    }
    // Check mobile screen
    this.mobileMediaQuery.addListener(e => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
    if (this.info.sell === "Now") {
      this.selling = true
    }
    if (this.info.advertised === "Yes") {
      this.advertising = true
    }

    if (this.info.sell === "Later") {
      this.sellingLater = true;
    }

    this.isNotShowErrMsg = true;

    this.$store.dispatch('setShowReservePriceBanner', !this.info.reservePrice ? false : true);

    // Check is close tab
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    if (this.authenticateData.customerPostalCode !== '') this.isValidLocation = true;

    this.$store.dispatch('setOnEditPage', false);

    // Package toggle
    if (this.isMobile) {
      this.toggleMobilePackage();
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  created() {
    this.handleFeeCalculation = debounce(this.getSuccessFee, 500);
    this.handleResolveDomain = debounce(this.checkEmailWithDns, 500);
  },
};
</script>

<style lang="scss">
.msg-error {
  position: absolute;
  font-size: 12px;
  color: red;
  text-align: left;
  float: right;
  margin-top: 2px;
}

.contact-content {
  color: #404040;
  .listed-message-banner {
    margin: 20px 18px 15px 18px;
  }
  .review-content .divider-wrapper .title {
    color: var(--primary-color);
  }
  .content-title {
    color: var(--primary-color);
  }

  .reserve-price-banner {
    background-color: var(--success-fee-background-color);
    padding: 6px 12px 6px 20px;
    border-radius: 5px;
    font-size: 14px;
    animation: fadeIn 0.5s ease both;
    .banner-text {
      color: var(--success-fee-text-color);
      display: flex;
      gap: 5px;
      .banner-icon {
        width: 16px;
        height: 16px;
      }
    }
    .banner-link {
      color: var(--success-fee-link-color);
      text-decoration: underline;
    }
  }
  .help-icon {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
  }
  .reserve-price-tooltip {
    position: absolute;
    top: -70px;
    right: -10px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #2E312E;
    color: white;
    font-size: 12px;
    text-align: left;
    width: 224px;
    visibility: hidden;
    z-index: 10;
  }
  .container-question {
    max-width: 345px;
    padding: 0 5px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    
    .item {
      margin-bottom: 0.5rem;
      u {
        color: var(--primary-color);
      }
      span {
        .not-collapsed {
          transform: rotate(180deg);
          transition: ease-in-out 0.3s;
        }
        .collapsed {
          transition: ease-in-out 0.3s;
        }
      }
      img {
        width: 14px;
        height: 8px;
        cursor: pointer;
        margin-left: 5px;
        transition: all .5s;
      }
    }
    .quick-answer {
      margin-top: 8px;
      margin-bottom: 8px;
      color: #6F6F6F;
      u {
        font-family: Avenir Next LT W05 Regular !important;
        font-weight: 700 !important;
        color: #0f0f0f;
      }
    }
  }

  .card {
    padding: 0 18px 15px !important;
    border: none !important;

    .card-body {
      padding: 0 !important;
    }
  }

  input {
    font-size: 16px;
    color: #383c3f;
    padding-left: 20px;
    font-weight: bold;
    border-color: #ced4da;
    &:focus {
      box-shadow: none;
      border-color: var(--primary-color);
      outline: 0;
    }

    &:disabled {
      border-color: transparent;
    }
  }

  .main-content {
    padding-bottom: 46px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .item {
      max-width: 314px;
      height: 40px;
      background-color: #f1f1f1;
      border-radius: 10px;
      padding: 0 12px;
      .year {
        width: 60px;
        height: 25px;
        background-color: #00b66c;
        border-radius: 5px;
      }
      .price {
        color: #00b66c;
      }
    }
    .text-note {
      color: #74bd58;
      font-size: 14px;
      text-align: left;
      max-width: 354px;
      margin-top: 17px;
      span {
        color: black !important;
      }
    }
  }
  .error,
  select.form-control.invalid,
  input.form-control.invalid {
    border-color: red !important;
  }
  
  .action {
    margin: 15px 0 0px;
    padding: 0px 8px 50px;

    .btn-action {
      width: 100%;
      height: 52px;
      padding: 16px 24px;
      margin-top: 12px;
      background-color: #C4C4C4;
      border-radius: 0.5rem;
      font-size: 16px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .active-btn-action {
      background-color: #2BB673;

      &:hover, &:active {
        background: linear-gradient(0deg, rgba(98, 0, 238, 0.08), rgba(98, 0, 238, 0.08)), #2BB673 !important;
      }
    }

    .btn-loading {
      font-size: 16px;
      width: 24px;
      height: 24px;
    }

    .btn-back {
      background-color: #ffffff !important;
      border: 1px solid #2BB673;
      color: #2BB673;
    }

    .btn-submit {
      &:hover, &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #C4C4C4;
        outline: none;
      }
    }
  }

  .seller-customer {
    color: #000000;
    width: 100%;
    padding: 0 5px;
    .seller-checkbox-err {
      color: red !important;
      font-style: italic;
      min-height: 16px;
      font-size: 12px;
      font-family: unset;
      margin: unset;
    }

    .seller-checkbox {
      a {
        text-decoration: underline;
        color: #000000;
      }

      input {
        accent-color: var(--primary-color);
      }

      .seller-checkbox-label {
        display: flex;
        align-items: center;
        position: relative;
        span {
          flex-shrink: 50;
        }
      }

      .custom-control-input.is-invalid ~ .custom-control-label,
      .custom-control-input.is-valid ~ .custom-control-label {
        color: #000000 !important;
      }

      .custom-control-input[disabled] ~ .custom-control-label, 
      .custom-control-input:disabled ~ .custom-control-label {
        color: #000000 !important;
      }

      .custom-control-input.is-valid ~ .custom-control-label::before {
        border-color: #adb5bd !important;
      }

      .custom-control-input.is-invalid ~ .custom-control-label::before {
        border-color: red;
      }

      .msg-error {
        font-family: Avenir Next LT W05 Medium;
      }
    }

    input:checked ~ label::before {
      color: #ffffff;
      background-color: #ffffff !important;
      border-color: #ffffff !important;
    }
    .readonly-checkbox {
      path.check-icon {
        fill: #C4C4C4;
      }
    }
    input[type='checkbox']{
      position: relative;
      cursor: pointer;
      width: 21px !important;
      height: 21px !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      outline: 1px solid #bcbcbc;
      box-shadow: none;
      font-size: 16px;
      text-align: center;
      line-height: 20px;
      background: transparent;
    }
    input[type='checkbox']:checked {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0;
    }
    .seller-checkbox-text {
      margin: 10px 0 0 10px;
      font-size: 14px;
      font-family: Avenir Next LT W05 Regular;
    }

    a {
      color: #000000;
      text-decoration: underline;
    }
    span {
      margin: 10px 0 0 10px;
      font-size: 14px;
      font-family: Avenir Next LT W05 Regular;
    }
  }
  .btn-group-wrapper {
    .action-btn {
      border: 1px solid var(--primary-color);
    }
  }
  .reserve-price-note {
    color: #000000;
    a {
      font-size: 14px;
      text-decoration: none;
      color: var(--primary-color);
    }
  }
  .package-container {
    .package-main {
      flex-direction: column;
      .package-list {
        background-color: #ffffff;
        .package-items {
          position: relative;
          width: 100%;
          height: 92px;
          border: 1px solid #EAECF0;
          border-radius: 8px;
          .package-fee {
            margin-top: 10px;
          }
        }
        .most-popular-badge {
          position: absolute;
          top: -12px; /* Adjust this to position the badge */
          left: 50%;
          transform: translateX(-50%);
          background-color: #E4ECFB;
          color: #0041D1;
          padding: 3px 7px;
          border-radius: 4px;
        }
        .custom-border {
          border-radius: 8px 8px 0 0;
        }
        .custom-selected-border-bottom {
          border: 1px solid #00BB65 !important;
        }
        .custom-selected-border-bottom-activated {
          border-bottom: none !important;
        }
        .custom-border-error-bottom {
          border: 1px solid #B42318 !important;
        }
        .custom-border-error-bottom-activated {
          border-bottom: none !important;
        }
        .custom-selected-border-top {
          border: 1px solid #00BB65 !important;
        }
        .custom-selected-border-top-activated {
          border-top: none !important;
        }
        .custom-border-error-top {
          border: 1px solid #B42318 !important;
        }
        .custom-border-error-top-activated {
          border-top: none !important;
        }
        .package-feature {
          height: 160px;
          border: 1px solid #EAECF0;
          border-top: 1px solid #EAECF0 !important;
          border-radius: 0 0 8px 8px;
          color: #000000;
          .package-feature-title {
            margin-top: 10px;
          }
        }
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
          background-color: #2BB673; /* Green color for the center */
          border-color: #2BB673; /* Green border */
          box-shadow: 0 0 0 4px rgba(40, 167, 69, 0.2); /* Green shadow effect */
        }
  
        .custom-radio .custom-control-input:not(:checked) ~ .custom-control-label::before {
          background-color: #fff; /* White center when not checked */
          border-color: #D0D5DD; /* Green border */
        }
  
        .custom-radio .custom-control-input:focus ~ .custom-control-label::before {
          box-shadow: 0 0 0 4px rgba(40, 167, 69, 0.2); /* Focus shadow */
        }
        .custom-radio .custom-control-label {
          cursor: pointer !important;
        }
        .custom-radio .custom-control-input:disabled ~ .custom-control-label::before {
          background-color: #EDEFF5 !important;
          border-color: #D8DAE5 !important;
          box-shadow: unset !important;
        }
      }
    }
    .package-compare {
      color: var(--primary-color);
      a {
        text-decoration: none;
        color: var(--primary-color);
      }
    }
    .package-err-text {
      color: #B42318;
    }
    .listing-tiers-disclaimer {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      font-family: Avenir Next LT W05 Regular;
      max-width: 360px;
      margin: 0 auto;
      .listing-tiers-disclaimer-content {
        margin-bottom: 4px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .contact-wrapper {
    min-height: calc(100vh - 409px);
    .contact-content {
      .card {
        padding: 0 3rem 1.5rem !important;
        overflow-y: hidden;
        .card-body {
          padding: 0 1.25rem 1.25rem !important;
          .customer-name-input {
            margin-top: 15px;
          }
          .row .col-md-6 {
            padding: 0 0.5rem !important;
          }
          .btn-group-wrapper {
            padding-bottom: 15px !important;
            padding-left: 77px !important;
            padding-right: 77px !important;
          }
          .divider-wrapper {
            display: flex !important;
          }
        }
      }

      .action {
        flex-direction: row !important;
        justify-content: space-between;
        .btn-action {
          width: unset !important;
        }
      }

      .main-content {
        padding-bottom: 60px !important;
        .info-vehicle {
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          max-width: 655px;
          margin: 0 auto;

          .item {
            min-width: 280px;
            margin: 0 !important;
            padding: 0 22px !important;

            .price {
              font-size: 20px !important;
            }
          }

        }
        .vehicle {
          max-width: 980px;
          display: flex;
          justify-content: space-between;
        }
        .text-note {
          max-width: 980px !important;
          margin-top: 17px !important;
          margin-left: auto !important;
          margin-right: auto;
          padding: 0 !important;
        }
      }
      .reserve-price-banner {
        font-size: 14px;
          .banner-icon {
            margin-top: 2px;
          }
      }
    }
  }

  .seller-customer {
    padding: 0 !important;
    width: 500px !important;
  }
  .package-main {
    flex-direction: row !important;
    margin-top: 25px !important;
  }
  .listing-tiers-disclaimer {
    max-width: unset !important;
    margin: unset !important;
  }
}

@media screen and(min-width: 900px) {
  .container-question {
    padding: 0 !important;
    margin-left: 0 !important;
    span {
      &:hover {
        transform: translateY(-1.5px);
        transition: ease-in-out 0.2s;
      }
    }
  }
}
@media screen and(max-width: 991px) {
  .container-question {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media screen and(min-width: 1200px) {
  .contact-content {
    .listed-message-banner {
      margin-left: 60px;
      margin-right: 60px;
    }
    .help-icon {
      right: 14px;
    }
    .package-list {
      width: 300px !important;
    }
  }
}
@media screen and(min-width: 768px) and(max-width: 1200px) {
  .contact-content {
    .listed-message-banner {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
}
@media screen and(min-width: 768px) and(max-width: 990px) {
  .package-list {
    width: 175px !important;
    .package-feature {
      height: 220px !important;
    }
    .most-popular-badge {
      p {
        width: 110px !important;
        font-size: 12px !important;
        text-align: center !important;
      }
    }
  }
}
@media screen and(min-width: 990px) and(max-width: 1200px) {
  .package-list {
    width: 250px !important;
    .package-feature {
      height: 180px !important;
    }
  }
}

</style>
